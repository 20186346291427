import React, { Fragment, useState } from 'react';
import { ExtendCSS, Text, Spacer, Flex, View } from 'vcc-ui';
import { Button, Link } from '@vcc-www/buttons';
import {
  maxContentWidth12Columns,
  maxContentWidth10Columns,
} from '@vcc-www/constants/layout';
import AnimatedReveal from '@vcc-www/animated-reveal';
import Video, { VideoContentType } from '@vcc-www/video';
import Disclaimer from '@vcc-www/disclaimer';
import { Markdown_DEPRECATED_VCCUI } from '@vcc-www/markdown';
import { ModelIntroProps } from './ModelIntro.types';
import VideoOverlay from './VideoOverlay';
import HeadingAndText from '@vcc-www/heading-and-text/HeadingAndText';
import { useBreakpoints } from '@vcc-www/hooks/useBreakpoints';

const ModelIntro: React.FC<React.PropsWithChildren<ModelIntroProps>> = ({
  content,
  centered = false,
  variant = 'large',
  contentAlignOnMobile = 'center',
  contentAlignOnDesktop = 'center',
  linkVariant = 'link',
  gridLayoutSize = '6',
}) => {
  const {
    description,
    heading,
    videoOverlayCtaLabel,
    videos,
    link1,
    link2,
    preHeading,
    headingTag,
    disclaimer,
    hasShowMore = false,
    showMoreText,
  } = content;

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const showVideoOverlayCta = videoOverlayCtaLabel && !!videos;
  const links = [link1, link2].filter((l) => !!l?.href);
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { untilM } = useBreakpoints();

  type HeadingTag =
    | ((
        | React.ComponentType<React.PropsWithChildren<any>>
        | keyof JSX.IntrinsicElements
      ) &
        string)
    | undefined;
  type HeadingVariant = 'ootah' | 'cook';
  type HeadingSubStyle = 'standard' | 'emphasis';

  const headingTagValue: HeadingTag = headingTag || 'h2';
  const headingVariant: HeadingVariant = variant.includes('regular')
    ? 'ootah'
    : 'cook';
  const headingSubStyle: HeadingSubStyle =
    variant === 'regular' ? 'standard' : 'emphasis';

  return (
    <>
      {preHeading && (
        <AnimatedReveal>
          <Text
            as="em"
            variant="hillary"
            subStyle="emphasis"
            data-testid="ModelIntro:pre-heading"
            extend={preHeadingCSS(centered, contentAlignOnMobile)}
          >
            {preHeading}
          </Text>
        </AnimatedReveal>
      )}
      {preHeading && (heading || description) && <Spacer size={2} />}
      {heading && (
        <AnimatedReveal>
          <Text
            as={headingTagValue}
            variant={headingVariant}
            subStyle={headingSubStyle}
            data-testid="ModelIntro:model"
            extend={modelCSS(centered, contentAlignOnMobile, gridLayoutSize)}
          >
            {heading}
          </Text>
        </AnimatedReveal>
      )}
      {heading && description && <Spacer size={4} />}
      {description && (
        <AnimatedReveal stagger={1}>
          {untilM && hasShowMore && showMoreText && showMoreText.length > 0 ? (
            <HeadingAndText
              text={description}
              readMoreTitle={showMoreText}
              supportMarkdown
              extendTextCss={descriptionCSS(
                centered,
                contentAlignOnMobile,
                contentAlignOnDesktop,
              )}
              data-autoid="imageWithText:description"
            />
          ) : (
            <Markdown_DEPRECATED_VCCUI
              variant="hillary"
              data-testid="ModelIntro:description"
              markdown={description}
              extend={descriptionCSS(
                centered,
                contentAlignOnMobile,
                contentAlignOnDesktop,
              )}
            />
          )}
        </AnimatedReveal>
      )}
      {showVideoOverlayCta && (
        <>
          <Flex extend={ctaCSS(centered)}>
            <Link
              data-autoid="ModelIntro:link"
              data-testid="ModelIntro:link"
              onClick={() => setIsOverlayOpen(true)}
              arrow="right"
            >
              {videoOverlayCtaLabel}
            </Link>
          </Flex>
          <VideoOverlay
            {...{
              isOverlayOpen,
              setIsOverlayOpen,
            }}
          >
            {isOverlayOpen && (
              <Video
                aspectRatio={[16, 9]}
                content={{ videos: videos as VideoContentType['videos'] }}
                autoplay
                loop
                extend={videoCSS}
              />
            )}
          </VideoOverlay>
        </>
      )}
      {links.length ? (
        <Flex extend={linkCtaCSS(centered)}>
          {links.map((link, index) => (
            <Fragment key={index}>
              {index === 1 && <Spacer size={3} />}
              {linkVariant === 'button' || linkVariant === 'download-button' ? (
                <View extend={buttonWrapperCSS}>
                  <Button
                    variant="outline"
                    href={link?.href}
                    target={link?.target}
                    aria-label={link?.accessibilityLabel}
                    download={linkVariant === 'download-button'}
                    trackEventAction="button|click"
                    trackEventLabel={`${link?.text} | ${link?.href}`}
                    // @ts-ignore Check if this is correct value to track for ga4
                    trackGA3={{ eventAction: 'click' }}
                  >
                    {link?.text}
                  </Button>
                </View>
              ) : (
                <Link
                  href={link?.href}
                  target={link?.target}
                  aria-label={link?.accessibilityLabel}
                  trackEventLabel={`${link?.text} | ${link?.href}`}
                  data-autoid={`ModelIntro:link${index + 1}`}
                >
                  {link?.text}
                </Link>
              )}
            </Fragment>
          ))}
        </Flex>
      ) : null}
      {disclaimer && (
        <Flex>
          <Spacer size={6} />
          <Disclaimer
            extend={({ theme: { baselineGrid } }) => ({
              marginTop: baselineGrid * 4,
            })}
            text={disclaimer}
          />
        </Flex>
      )}
    </>
  );
};

export default ModelIntro;

const modelCSS = (
  centered?: ModelIntroProps['centered'],
  contentAlignOnMobile?: ModelIntroProps['contentAlignOnMobile'],
  gridLayoutSize?: ModelIntroProps['gridLayoutSize'],
): ExtendCSS => ({
  maxWidth:
    gridLayoutSize === '10'
      ? maxContentWidth10Columns
      : maxContentWidth12Columns / 2,
  ...(centered && {
    textAlign: 'center',
  }),
  fromM: {
    textAlign: 'center',
  },
  untilM: {
    ...(contentAlignOnMobile === 'left' && {
      textAlign: 'start',
    }),
  },
});

const descriptionCSS =
  (
    centered?: boolean,
    contentAlignOnMobile?: string,
    contentAlignOnDesktop?: string,
  ): ExtendCSS =>
  ({ theme: { color } }) => ({
    color: color.foreground.secondary,
    maxWidth: maxContentWidth12Columns / 2,
    whiteSpace: 'pre-line',
    '& > p:last-of-type': {
      display: 'inline-block',
    },
    ...(centered && {
      textAlign: 'center',
    }),
    fromM: {
      textAlign: contentAlignOnDesktop === 'left' ? 'start' : 'center',
    },
    untilM: {
      ...(contentAlignOnMobile === 'left' && {
        textAlign: 'start',
      }),
    },
  });

const ctaCSS =
  (centered: boolean, contentAlignOnMobile?: string): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    whiteSpace: 'nowrap',
    paddingTop: 3 * baselineGrid,
    cursor: 'pointer',
    alignItems: centered ? 'center' : 'normal',
    fromM: {
      alignSelf: 'center',
    },
    untilM: {
      ...(contentAlignOnMobile === 'left' && {
        textAlign: 'start',
      }),
    },
  });
const linkCtaCSS =
  (centered: boolean, contentAlignOnMobile?: string): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    whiteSpace: 'nowrap',
    flexDirection: 'row',
    paddingTop: 3 * baselineGrid,
    justifyContent: centered ? 'center' : 'normal',
    fromM: {
      alignSelf: 'center',
    },
    untilM: {
      ...(contentAlignOnMobile === 'left' && {
        textAlign: 'start',
      }),
      flexFlow: 'row wrap',
    },
  });

const videoCSS: ExtendCSS = {
  backgroundImage: 'none',
  backgroundColor: 'black',
  outline: 'none',
};
const preHeadingCSS =
  (centered?: boolean, contentAlignOnMobile?: string): ExtendCSS =>
  ({ theme: { color } }) => ({
    color: color.foreground.primary,
    maxWidth: maxContentWidth12Columns / 2,
    ...(centered && {
      textAlign: 'center',
    }),
    fromM: {
      textAlign: 'center',
    },
    untilM: {
      ...(contentAlignOnMobile === 'left' && {
        textAlign: 'start',
      }),
    },
  });

const buttonWrapperCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  marginTop: 3 * baselineGrid,
});
