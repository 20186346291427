import React, { useCallback, useEffect, useState } from 'react';
import { ExtendCSS, Flex, Block } from 'vcc-ui';
import {
  CloseButton,
  OverlayDEPRECATED,
} from '@vcc-package/overlays/DEPRECATED';
import VideoOverlayProps from './props';

const VideoOverlay: React.FC<React.PropsWithChildren<VideoOverlayProps>> = (
  props,
) => {
  const { isOverlayOpen, setIsOverlayOpen, ...params } = props;
  const { children } = params;
  const [hasExited, setHasExited] = useState(true);
  const closeOverlay = useCallback(
    () => setIsOverlayOpen(false),
    [setIsOverlayOpen],
  );

  useEffect(() => {
    if (isOverlayOpen && hasExited) {
      setHasExited(false);
    }
  }, [isOverlayOpen]); // eslint-disable-line

  return (
    <OverlayDEPRECATED
      isOpen={isOverlayOpen}
      close={closeOverlay}
      hideCloseButton
      showFloatingCloseButton={false}
      onExited={() => setHasExited(true)}
      resetScroll={false}
      extend={overlayCSS}
      extendContent={overlayContentCSS}
    >
      <Flex extend={flexCSS} data-testid="flex">
        <Block extend={blockCSS} data-testid="block">
          <Flex extend={closeButtonContainer}>
            <CloseButton close={() => setIsOverlayOpen(false)} showLarge />
          </Flex>
          {children}
        </Block>
      </Flex>
    </OverlayDEPRECATED>
  );
};

const aspectRatio = '16/9';
const maxVideoWidth = '918px';
const closeButtonHeight = '40px';

const overlayCSS: ExtendCSS = {
  padding: 0,
  paddingTop: 0,
  margin: 0,
};

const overlayContentCSS: ExtendCSS = {
  padding: 0,
  margin: 0,
  paddingTop: 0,
};

const flexCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  width: '100vw',
  height: '100vh',
  maxWidth: `calc(${maxVideoWidth} + ${baselineGrid * 4}px)`,
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
});

const blockCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  width: '100%',
  maxWidth: `calc((100vh - ${closeButtonHeight}) * ${aspectRatio})`,
  fromM: {
    paddingRight: `${baselineGrid * 3}px`,
    paddingLeft: `${baselineGrid * 3}px`,
    paddingBottom: `${baselineGrid * 2}px`,
  },
});

const closeButtonContainer: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: `${baselineGrid}px`,
  marginBottom: `${baselineGrid}px`,
  untilM: {
    position: 'absolute',
    maxWidth: `calc((100vh - ${closeButtonHeight}) * ${aspectRatio})`,
    top: 0,
    right: 0,
  },
});

export default VideoOverlay;
