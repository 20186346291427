import React from 'react';
import { ExtendCSS } from 'vcc-ui';
import SectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import ModelIntroBase from '@vcc-www/model-intro';
import ModelIntroProps from './ModelIntro.props';

const ModelIntro: React.FC<ModelIntroProps> = ({
  content,
  centered = false,
  autoplay,
  largePlayIcon,
  lazyLoadPoster,
  loop,
  centeredPlayIcon,
  variant = 'large',
  marginsOnDesktop = true,
  marginsOnMobile = true,
  contentAlignOnMobile = 'center',
  contentAlignOnDesktop = 'center',
  linkVariant = 'link',
  gridLayoutSize = '6',
  ...params
}) => (
  <SectionContained
    {...params}
    extend={sectionCSS(centered, marginsOnDesktop, contentAlignOnMobile)}
    data-autoid="ModelIntro"
    marginsOnMobile={marginsOnMobile}
  >
    <ModelIntroBase
      {...{
        content,
        centered,
        variant,
        contentAlignOnMobile,
        contentAlignOnDesktop,
        linkVariant,
        gridLayoutSize,
      }}
    />
  </SectionContained>
);

export default ModelIntro;

const sectionCSS =
  (
    centered?: boolean,
    marginsOnDesktop?: boolean,
    contentAlignOnMobile?: string,
  ): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: centered ? 'center' : 'flex-start',
    fromM: {
      alignItems: 'center',
      ...(marginsOnDesktop === false && {
        width: '100%',
        maxWidth: '100%',
      }),
    },
    untilM: {
      paddingLeft: baselineGrid * 3,
      paddingRight: baselineGrid * 3,
      ...(contentAlignOnMobile === 'left' && {
        textAlign: 'start',
        alignItems: 'flex-start',
      }),
      ...(centered &&
        contentAlignOnMobile === 'center' && {
          textAlign: 'center',
          alignItems: 'center',
        }),
    },
  });
